import axios from "axios";

const axiosInstance = axios.create({
    timeout: 5000,
});

let baseUrl = process.env.VUE_APP_BASE_URL

export default {
    login(postData) {
        let loginUrl = `${baseUrl}/login`;

        return axiosInstance.post(loginUrl, postData)
    },
}
<template>
  <div>
    <div>
      <h4 style="font-weight: bold">Login</h4>
      <b-form>
        <label>
          <input hidden name="redirect" v-bind:value="redirect">
        </label>
        <label>
          <input hidden name="state" v-bind:value="state">
        </label>
        <label>
          <input hidden name="scope" v-bind:value="scope">
        </label>
        <label>
          <input hidden name="client_id" v-bind:value="clientId">
        </label>

        <b-form-group
            id="input-group-1"
            label="Nickname:"
            label-for="username"
            description="">
          <b-form-input
              v-on:keyup.enter="submit"
              name="username"
              v-model="username"
              id="username"
              type="text"
              placeholder="Enter nickname"
              required>
          </b-form-input>
        </b-form-group>

        <b-form-group
            id="input-group-2"
            label="Password:"
            label-for="password"
            description="">

          <b-form-input
              v-on:keyup.enter="submit"
              name="password"
              v-model="password"
              id="password"
              type="password"
              placeholder="Enter password"
              required>
          </b-form-input>
        </b-form-group>

        <b-form-group
            id="input-group-2"
            label="2FA Code:"
            label-for="twofa"
            description="">

          <b-form-input
              v-on:keyup.enter="submit"
              name="authcode"
              v-model="authcode"
              id="twofa"
              type="tel"
              autocomplete="off"
              placeholder="Enter 2FA Code">
          </b-form-input>
        </b-form-group>

        <div style="text-align: right">
          <b-button type="button" @click="submit" variant="success">Login</b-button>
        </div>

      </b-form>
    </div>

  </div>
</template>
<script>

import applicationService from "@/services/ApplicationService";

export default {
  name: 'LoginForm',
  mounted() {
  },
  data: function () {
    return {
      username: '',
      password: '',
      authcode: '',
      redirect: this.$route.query.redirect_uri,
      state: this.$route.query.state,
      scope: this.$route.query.scope,
      submitUrl: '',
      clientId: this.$route.query.client_id
    }
  },
  methods: {
    submit: function () {
      let context = this;

      context.$bvToast.toast(` `, {
        title: 'Logging in...',
        solid: true
      });

      applicationService.login({
        redirect: this.redirect,
        state: this.state,
        scope: this.scope,
        client_id: this.clientId,
        username: this.username,
        password: this.password,
        authcode: this.authcode
      }).then(function (response) {
        console.log(response);
        window.location.href = response.data.redirect;
      }).catch(function (error) {
        if (error.response) {
          let response = error.response;

          context.$bvToast.toast(`Error ${response.status}: ${response.statusText} (Message: ${JSON.stringify(response.data)})`, {
            title: 'Error while logging in',
            variant: 'danger',
            solid: true
          });
        } else {
          let message = error.message

          if (message.includes("timeout")) {
            context.$bvToast.toast(`Timeout! Server not reachable`, {
              title: 'Error while logging in',
              variant: 'danger',
              solid: true
            });
          } else {
            context.$bvToast.toast(`Unbekannter Error: ${message}`, {
              title: 'Error while logging in',
              variant: 'danger',
              solid: true
            });
          }
        }

      });
    }
  }
}

</script>
<template>
  <div id="app">
    <div class="video-view">
      <video autoplay muted loop class="backgroundVideo" v-bind:src="backgroundSrc" type="video/mp4">
      </video>
      <SettingsDropdown v-bind:quality="width" @changeQuality="qualityChanged"></SettingsDropdown>
    </div>

    <div id="backgroundColor"></div>
    <div id="wrapper">
      <div id="header">
        <h2 style="font-weight: bold">Timolia Login</h2>
        <img width="100" src="@/assets/logo.png"/>
      </div>

      <router-view></router-view>
    </div>
  </div>
</template>

<script>

import SettingsDropdown from "./components/SettingsDropdown";
export default {
  name: 'App',
  components: {SettingsDropdown},
  data: function () {
    return {
      width: ''
    }
  },

  mounted() {
    const quality = this.$cookies.get('quality')
    if (quality !== null) {
      console.log('load with cookie: ' + quality);
      this.width = quality;
      return;
    }

    let windowWidth = window.innerWidth;

    if (windowWidth > 0 && windowWidth < 720) {
      this.width = 'low'
    }
    if (windowWidth > 721 && windowWidth < 3839) {
      this.width = 'medium'
    }
    if (windowWidth > 3840) {
      this.width = 'high'
    }
  },
  methods: {
    qualityChanged(level) {
      this.width = level;
      this.$cookies.set('quality', level, -1);
    }
  },
  computed: {
    backgroundSrc: function () {
      switch (this.width) {
        case "high":
          return require(`@/assets/timoliaLoop_4k.mp4`)
        case "medium":
          return require(`@/assets/timoliaLoop_FullHD.mp4`)
        case "low":
          return require(`@/assets/timoliaLoop.mp4`)
        case "ultra-low":
          return require(`@/assets/144pLobbyRender.mp4`)
        default:
          return '';
      }
    }
  }
}
</script>

<style scoped>

body {
}

.backgroundVideo {
  position: fixed;
  left: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  max-width: 100%;
  filter: blur(2px);
  z-index: 0;
}
#backgroundColor {
  position: fixed;
  left: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  z-index: -1;
  background: #000b23;
}

#wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

#header {
  text-align: center;
}

#wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 360px;
}

</style>

<template>
  <div class="menu">
    <a class="accordion"><i class="fas fa-sliders-h fa-2x"></i></a>
    <div class="panel">
      <form>
        <label class="container">Ultra Low
          <input type="radio" name="radio" value="ultra-low" :checked="quality === 'ultra-low'" v-on:change="qualityChanged('ultra-low')">
          <span class="checkmark"></span>
        </label>
        <label class="container">Low
          <input type="radio" name="radio" value="low" :checked="quality === 'low'" v-on:change="qualityChanged('low')">
          <span class="checkmark"></span>
        </label>
        <label class="container">Medium
          <input type="radio" name="radio" value="medium" :checked="quality === 'medium'" v-on:change="qualityChanged('medium')">
          <span class="checkmark"></span>
        </label>
        <label class="container">High
          <input type="radio" name="radio" value="high" :checked="quality === 'high'" v-on:change="qualityChanged('high')">
          <span class="checkmark"></span>
        </label>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['quality'],
  name: 'SettingsDropdown',
  mounted() {
    console.log('Load with quality: ' + this.quality);

    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        /* Toggle between adding and removing the "active" class,
        to highlight the button that controls the panel */
        this.classList.toggle("active");

        /* Toggle between hiding and showing the active panel */
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  },
  methods: {
    qualityChanged(level) {
      this.$emit('changeQuality', level)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background: rgba(0, 0, 0, 0.0);
  color: #03173d;
  cursor: pointer;
  padding: 18px;
  width: 5%;
  text-align: right;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .accordion:hover {
  color: #fff;
}
.accordion:active {
  background: rgba(0, 0, 0, 0.0);
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 22px 18px;
  display: none;
  overflow: hidden;
  text-align: left;
  color: #bababa;
}

.menu {
  position: fixed;
}

/* Button Section */
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #03173d;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #0f3ca8;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #092a68;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
</style>
